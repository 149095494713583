import { useFlagMe269599EnableTableCustomizationSettingsMigration } from '@generated/flags/ME-269599-enable-table-customization-settings-migration';
import { idbConfig } from '@hooks/useIndexedDB/config';
import { getConnection } from '@hooks/useIndexedDB/db';
import {
  AllTablePreferences,
  useTablePreference,
} from '@hooks/useTablePreference';
import { reportCustomSentryError } from '@utils/sentry';
import { atom, useAtom } from 'jotai';
import { FC, useEffect, useState } from 'react';
import { useMount } from 'react-use';

export const tablePrefsAtom = atom<AllTablePreferences | undefined>(undefined);
export const tablePrefsLoadedAtom = atom(false);

export const TablePreferenceInitializer: FC = ({ children }) => {
  const shouldUseIDB =
    useFlagMe269599EnableTableCustomizationSettingsMigration();

  const [initialized, setInitialized] = useState(!shouldUseIDB);
  const [allTablePreferences] = useTablePreference(undefined, true);
  const [, setGlobalTablePrefsState] = useAtom(tablePrefsAtom);

  // Update global table preferences when hook preferences change.
  useEffect(() => {
    setGlobalTablePrefsState(allTablePreferences);
  }, [setGlobalTablePrefsState, allTablePreferences]);

  useMount(() => {
    // Block the app from rendering until IDB is initialized.
    getConnection(idbConfig)
      .catch((error) => {
        reportCustomSentryError(`Failed to setup IndexDB: ${error}`);
      })
      .finally(() => {
        // Set as initialized even if there is an error. App won't render without this.
        setInitialized(true);
      });
  });

  if (initialized) {
    return children as fixMe;
  }
  return null;
};
