import {
  SIDEBAR_BORDER_WIDTH,
  SIDEBAR_OPEN_WIDTH,
  SidebarContext,
} from '@app/Sidebar/util';
import { Button } from '@components/Button';
import { useDEPRECATEDFlagBasedOnJSONDomain } from '@components/Flag';
import { Grid } from '@components/Grid';
import { Link } from '@components/Link';
import { Logo } from '@components/Logo';
import { NavLink } from '@components/NavLink';
import { openNewWindow } from '@components/NewWindowLink';
import { systemBanner } from '@components/SystemAlerts/SystemBanner';
import { ToggleMenu } from '@components/ToggleMenu';
import { useFlagMe115898ViewDriverRecord } from '@generated/flags/ME-115898-view-driver-record';
import { useFlagMe115902ViewDriverPreferences } from '@generated/flags/ME-115902-view-driver-preferences';
import { useFlagMe115918ViewDriverRecordTargetsAndPerformance } from '@generated/flags/ME-115918-view-driver-record-targets-and-performance';
import { useFlagMe115975ViewDriverRecordHistory } from '@generated/flags/ME-115975-view-driver-record-history';
import { useFlagMe115985ViewTrailerRecord } from '@generated/flags/ME-115985-view-trailer-record';
import { useFlagMe115990ViewPowerRecord } from '@generated/flags/ME-115990-view-power-record';
import { useFlagMe117305ViewDriverPay } from '@generated/flags/ME-117305-view-driver-pay';
import { FlagMe161096EnableBulkRerating } from '@generated/flags/ME-161096-enable-bulk-rerating';
import { FlagMe172708FeatChat } from '@generated/flags/ME-172708-feat-chat';
import { FlagMe213647ViewRateDetailQueueMmFe } from '@generated/flags/ME-213647-view-rate-detail-queue-mm-fe';
import { FlagMe220243EnablePlannedNetworkManagement } from '@generated/flags/ME-220243-enable-planned-network-management';
import { useFlagMe23255FeatMassUploadLoad } from '@generated/flags/ME-23255-feat-mass-upload-load';
import { FlagMe256006EnablePlanningLanesTab } from '@generated/flags/ME-256006-enable-planning-lanes-tab';
import { FlagMe280089ManageGroups } from '@generated/flags/ME-280089-manage-groups';
import { FlagMe30154ViewVendorInvoiceTab } from '@generated/flags/ME-30154-view-vendor-invoice-tab';
import { FlagMe32174FeatAssets } from '@generated/flags/ME-32174-feat-assets';
import { useFlagMe323572SaoOrderCreationForm } from '@generated/flags/ME-323572-sao-order-creation-form';
import { useFlagMe340307ViewOrderPlanningList } from '@generated/flags/ME-340307-view-order-planning-list';
import { FlagMe65797ViewProjectPage } from '@generated/flags/ME-65797-view-project-page';
import { FlagadminReleaseLock } from '@generated/flags/admin-release-lock';
import { FlagnavAdmin } from '@generated/flags/nav-admin';
import { useDebouncedFn } from '@hooks/useDebouncedFn';
import { useMinionFlag } from '@hooks/useMinionFlag';
import { MinionConfigTypes } from '@minion/types';
import { HEADER_HEIGHT, NEW_WINDOW_TARGET, USE_WALKME } from '@utils/constants';
import { FS_MASK, FS_UNMASK } from '@utils/fullstory';
import { win } from '@utils/win';
import { APP_HEADER } from '@utils/zIndex';
import { HasPermission } from '@views/Common/Permissions';
import { FC, useContext, useState } from 'react';
import { useMount } from 'react-use';
import { ChatButton } from '../ChatButton';
import { MasterFindButton } from '../MasterFindButton';
import { UserNav } from '../UserNav';
import { MenuLink } from './MenuLink';

export const WALKME_TOGGLE_BUTTON_ID = 'walkme-toggle';

const backgroundCSSVar = `var(--header-stripe-color)`;

const DefaultAccountingFields: FC = () => {
  return (
    <ToggleMenu text="Accounting" id="nav-link-accounting-menu">
      <HasPermission scope="accounting:customerSettlement:view">
        <MenuLink
          as={NavLink as fixMe}
          to={'/accounting/uninvoiced'}
          rel="noopener noreferrer"
          data-testid="nav-link-accounting-uninvoiced"
          index={1}
        >
          Uninvoiced
        </MenuLink>
      </HasPermission>
      <HasPermission scope="accounting:invoices:view">
        <MenuLink
          as={NavLink as fixMe}
          to="/accounting/invoices"
          rel="noopener noreferrer"
          data-testid="nav-link-accounting-invoices"
          index={2}
        >
          Invoices
        </MenuLink>
      </HasPermission>
      <HasPermission scope="accounting:vendorSettlement:view">
        <MenuLink
          as={NavLink as fixMe}
          to="/accounting/unvouchered"
          rel="noopener noreferrer"
          data-testid="nav-link-accounting-unvouchered"
          index={3}
        >
          Unvouchered
        </MenuLink>
      </HasPermission>
      <HasPermission scope="accounting:vouchers:view">
        <MenuLink
          as={NavLink as fixMe}
          to="/accounting/vouchers"
          rel="noopener noreferrer"
          data-testid="nav-link-accounting-vouchers"
          index={4}
        >
          Vouchers
        </MenuLink>
      </HasPermission>
      <FlagMe30154ViewVendorInvoiceTab>
        <HasPermission scope="accounting:vendorInvoice:view">
          <MenuLink
            as={NavLink as fixMe}
            to="/accounting/vendor-invoice"
            rel="noopener noreferrer"
            data-testid="nav-link-accounting-vendor-invoice"
            index={5}
          >
            Vendor Invoice
          </MenuLink>
        </HasPermission>
      </FlagMe30154ViewVendorInvoiceTab>
      <FlagMe213647ViewRateDetailQueueMmFe>
        <MenuLink
          as={NavLink as fixMe}
          to="/accounting/rate-line-items"
          rel="noopener noreferrer"
          data-testid="nav-link-accounting-rate-line-items"
          index={6}
        >
          Rate Line Items
        </MenuLink>
      </FlagMe213647ViewRateDetailQueueMmFe>
      <HasPermission scope="accounting:bulkRerating:view">
        <FlagMe161096EnableBulkRerating>
          <MenuLink
            as={NavLink as fixMe}
            to="/accounting/bulk-rerating"
            rel="noopener noreferrer"
            data-testid="nav-link-accounting-bulk-rerating"
            index={7}
          >
            Bulk Rerating
          </MenuLink>
        </FlagMe161096EnableBulkRerating>
      </HasPermission>
    </ToggleMenu>
  );
};
const BaseHeader: FC = ({ children }) => {
  return (
    <nav
      css={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        background: backgroundCSSVar,
        height: HEADER_HEIGHT,
        padding: '0 16px',
        overflow: 'hidden',
        '-webkit-app-region': 'drag',
        userSelect: 'none',
      }}
      className={FS_UNMASK}
      data-testid="base-nav"
    >
      {children}
    </nav>
  );
};

const HeaderLogo: FC = () => {
  return (
    <Link
      to="/"
      data-testid="logo-link"
      aria-label="Return to home"
      css={{
        display: 'grid',
        placeItems: 'center',
        height: '100%',
        width: SIDEBAR_OPEN_WIDTH + SIDEBAR_BORDER_WIDTH,
        overflow: 'hidden',
      }}
    >
      <Logo width="48px" css={{ top: -1, left: -1, position: 'relative' }} />
    </Link>
  );
};

export const Header: FC = () => {
  const [menuKey, setMenuKeyRaw] = useState(Date.now());
  // we change the key on the menu to close the dropdown
  const closeAllMenus = useDebouncedFn(
    () => {
      setMenuKeyRaw(Date.now());
    },
    200,
    [],
    { leading: true }
  );
  const showFuelOption = useDEPRECATEDFlagBasedOnJSONDomain(
    'ME-24258-feat-mastermind-admin-fuel-services'
  );
  const showLoadMassUploadOption = useFlagMe23255FeatMassUploadLoad();
  const showDriverRecord = useFlagMe115898ViewDriverRecord();
  const showDriverPreferences = useFlagMe115902ViewDriverPreferences();
  const showDriverTargetsAndPerformance =
    useFlagMe115918ViewDriverRecordTargetsAndPerformance();
  const showDriverHistory = useFlagMe115975ViewDriverRecordHistory();
  const showDriverPay = useFlagMe117305ViewDriverPay();

  const showTrailerRecord = useFlagMe115985ViewTrailerRecord();
  const showPowerRecord = useFlagMe115990ViewPowerRecord();
  const SAOCreationFormEnabled = useFlagMe323572SaoOrderCreationForm();

  const viewOrderPlanningList = useFlagMe340307ViewOrderPlanningList();

  /* eslint-disable @typescript-eslint/no-unused-vars */
  const { isHeaderAllowed } = useContext(SidebarContext);

  const isOfferPageEnabled = useMinionFlag(MinionConfigTypes.OfferPage);

  useMount(() => {
    if (isHeaderAllowed) {
      win.document.addEventListener('scroll', () => {
        closeAllMenus();
      });
    }
    document.querySelector('#root')?.classList.remove('loading');
  });

  if (!isHeaderAllowed) {
    return null;
  }

  const newLoadLabel = (): string => {
    if (SAOCreationFormEnabled) {
      return 'Add New Load';
    } else {
      return 'Add New';
    }
  };

  return (
    <Grid
      xs="min-content 1fr"
      gap={0}
      css={{
        '-webkit-app-region': 'drag',
        userSelect: 'none',
        gridTemplateRows: HEADER_HEIGHT,
        background: backgroundCSSVar,
        position: 'sticky',
        top: 0,
        zIndex: APP_HEADER,
        ['[data-reach-menu]']: {
          zIndex: APP_HEADER + 1,
        },
      }}
    >
      <HeaderLogo />
      <BaseHeader key={menuKey}>
        <HasPermission scope="load:view">
          <ToggleMenu text="Loads" id="nav-link-load-menu">
            <HasPermission scope="load:view">
              <MenuLink
                to="/loads"
                as={NavLink as fixMe}
                data-testid="nav-link-load-search"
                index={0}
              >
                Search
              </MenuLink>
            </HasPermission>
            <HasPermission scope="load:create">
              <MenuLink
                to="/loads/create?bottom=0&row=0&section=order"
                as={NavLink as fixMe}
                target={NEW_WINDOW_TARGET}
                rel="noopener noreferrer"
                data-testid="nav-link-load-create"
                onClick={(event: fixMe): void => {
                  event.preventDefault();
                  openNewWindow({
                    to: `/loads/create?bottom=0&row=0&section=order`,
                  });
                  closeAllMenus();
                }}
                index={1}
              >
                {newLoadLabel()}
              </MenuLink>
            </HasPermission>
            <HasPermission scope="load:repeat">
              <MenuLink
                to="/load-repeat"
                as={NavLink as fixMe}
                rel="noopener noreferrer"
                data-testid="nav-link-load-repeat"
                index={2}
              >
                Repeat
              </MenuLink>
            </HasPermission>
            <MenuLink
              to="/track"
              as={NavLink as fixMe}
              target={
                win.location.pathname === '/' ? NEW_WINDOW_TARGET : undefined
              }
              rel="noopener noreferrer"
              data-testid="nav-link-track"
              data-walkme="track"
              index={3}
            >
              Track
            </MenuLink>
            {showLoadMassUploadOption && (
              <HasPermission scope="load:create">
                <MenuLink
                  to="/loads?upload=1"
                  as={NavLink as fixMe}
                  rel="noopener noreferrer"
                  data-testid="nav-link-load-upload"
                  index={4}
                >
                  Upload
                </MenuLink>
              </HasPermission>
            )}
            {SAOCreationFormEnabled && (
              <MenuLink
                to="/orders/create"
                as={NavLink as fixMe}
                target={NEW_WINDOW_TARGET}
                rel="noopener noreferrer"
                data-testid="nav-link-order-create"
                index={5}
              >
                Create Order
              </MenuLink>
            )}
            {viewOrderPlanningList && (
              <MenuLink
                to="/plan-load"
                as={NavLink as fixMe}
                target={NEW_WINDOW_TARGET}
                rel="noopener noreferrer"
                data-testid="nav-link-plan-load"
                onClick={(event: fixMe): void => {
                  event.preventDefault();
                  openNewWindow({
                    to: `/plan-load`,
                  });
                  closeAllMenus();
                }}
                index={6}
              >
                Plan Load
              </MenuLink>
            )}
          </ToggleMenu>
        </HasPermission>
        <HasPermission scope="book:view">
          <ToggleMenu text="Book" id="nav-link-book-menu">
            <HasPermission scope="book:stateMap:view">
              <MenuLink
                to="/map"
                as={NavLink as fixMe}
                rel="noopener noreferrer"
                data-testid="nav-link-map"
                index={0}
              >
                State Map
              </MenuLink>
            </HasPermission>
            <HasPermission scope="book:availableLoads:view">
              <MenuLink
                to="/available-routes"
                as={NavLink as fixMe}
                rel="noopener noreferrer"
                data-testid="nav-link-available-routes"
                index={1}
              >
                Available Routes
              </MenuLink>
            </HasPermission>
            <HasPermission scope="book:myMatches:view">
              <MenuLink
                to="/matches"
                as={NavLink as fixMe}
                rel="noopener noreferrer"
                data-testid="nav-link-my-matches"
                index={3}
              >
                My Matches
              </MenuLink>
            </HasPermission>
            {isOfferPageEnabled && (
              <HasPermission scope="offer:view">
                <MenuLink
                  to="/offers"
                  as={NavLink as fixMe}
                  rel="noopener noreferrer"
                  index={4}
                >
                  Offers
                </MenuLink>
              </HasPermission>
            )}
            <MenuLink
              to="/capacity/search"
              as={NavLink as fixMe}
              rel="noopener noreferrer"
              index={5}
            >
              Capacity Search
            </MenuLink>
            <HasPermission scope="book:optimatch:view">
              <MenuLink
                to="/optimatch"
                as={NavLink as fixMe}
                data-testid="nav-link-optimatch"
                target={NEW_WINDOW_TARGET}
                rel="noopener noreferrer"
                onClick={(event: fixMe): void => {
                  event.preventDefault();
                  openNewWindow({
                    to: `/optimatch`,
                  });
                  closeAllMenus();
                }}
                index={6}
              >
                OptiMatch
              </MenuLink>
            </HasPermission>
          </ToggleMenu>
        </HasPermission>
        <HasPermission scope="carrier:view">
          <ToggleMenu text="Carrier" id="nav-link-carrier-menu">
            <MenuLink
              to="/carriers/search"
              as={NavLink as fixMe}
              rel="noopener noreferrer"
              data-testid="nav-link-search-carrier"
              index={0}
            >
              Search
            </MenuLink>

            <HasPermission scope="carrier:create">
              <MenuLink
                to="/carriers/add"
                as={NavLink as fixMe}
                rel="noopener noreferrer"
                data-testid="nav-link-add-carrier"
                index={1}
              >
                Add New
              </MenuLink>
            </HasPermission>
          </ToggleMenu>
        </HasPermission>
        <HasPermission scope="customer:view">
          <ToggleMenu text="Customer" id="nav-link-customer-menu">
            <MenuLink
              to="/customers/search"
              as={NavLink as fixMe}
              rel="noopener noreferrer"
              data-testid="nav-link-search-customer"
              index={0}
            >
              Search
            </MenuLink>
            <HasPermission scope="customer:create">
              <MenuLink
                to="/customers/add"
                as={NavLink as fixMe}
                rel="noopener noreferrer"
                data-testid="nav-link-add-customer"
                index={1}
              >
                Add New
              </MenuLink>
            </HasPermission>
          </ToggleMenu>
        </HasPermission>
        <HasPermission scope="facility:view">
          <ToggleMenu text="Facility" id="nav-link-facility-menu">
            <MenuLink
              as={NavLink as fixMe}
              to="/facilities/search"
              rel="noopener noreferrer"
              data-testid="nav-link-search-facility"
              index={0}
            >
              Search
            </MenuLink>
            <HasPermission scope="facility:edit">
              <MenuLink
                to="/facilities/add"
                as={NavLink as fixMe}
                rel="noopener noreferrer"
                data-testid="nav-link-add-facility"
                index={1}
              >
                Add New
              </MenuLink>
            </HasPermission>
          </ToggleMenu>
        </HasPermission>
        <ToggleMenu text="Planning" id="nav-link-planning-menu">
          <FlagMe256006EnablePlanningLanesTab>
            <HasPermission scope="lane:view">
              <MenuLink
                as={NavLink as fixMe}
                to="/lanes"
                rel="noopener noreferrer"
                data-testid="nav-link-lane"
                index={0}
              >
                Lanes
              </MenuLink>
            </HasPermission>
          </FlagMe256006EnablePlanningLanesTab>
          <FlagMe65797ViewProjectPage>
            <MenuLink
              as={NavLink as fixMe}
              to="/projects"
              rel="noopener noreferrer"
              data-testid="nav-link-project"
              index={1}
            >
              Projects
            </MenuLink>
          </FlagMe65797ViewProjectPage>
          <FlagMe220243EnablePlannedNetworkManagement>
            <MenuLink
              as={NavLink as fixMe}
              to="/planned-network-management"
              rel="noopener noreferrer"
              data-testid="nav-link-planned-network-management"
              index={2}
            >
              Planned Network Management
            </MenuLink>
          </FlagMe220243EnablePlannedNetworkManagement>
        </ToggleMenu>

        <HasPermission scope="employee:view">
          <ToggleMenu text="Employee" id="nav-link-employee-menu">
            <MenuLink
              as={NavLink as fixMe}
              to="/employees/search"
              rel="noopener noreferrer"
              data-testid="nav-link-search-employee"
              index={0}
            >
              Search
            </MenuLink>
            <HasPermission scope="employee:create">
              <MenuLink
                as={NavLink as fixMe}
                to="/employees/add"
                rel="noopener noreferer"
                data-testid="nav-link-add-employee"
                index={1}
              >
                Add New
              </MenuLink>
            </HasPermission>
            <FlagMe280089ManageGroups>
              <HasPermission scope="employee:manageGroups:view">
                <MenuLink
                  as={NavLink as fixMe}
                  to="/groups"
                  rel="noopener noreferer"
                  data-testid="nav-link-group"
                  index={2}
                >
                  Groups
                </MenuLink>
              </HasPermission>
            </FlagMe280089ManageGroups>
          </ToggleMenu>
        </HasPermission>

        <FlagnavAdmin>
          <HasPermission scope="admin:view">
            <ToggleMenu text="Admin" id="nav-link-admin-menu">
              <FlagadminReleaseLock>
                <HasPermission scope="admin:releaseLock:view">
                  <MenuLink
                    as={NavLink as fixMe}
                    to="/admin/release-lock"
                    rel="noopener noreferrer"
                    data-testid="nav-link-admin-release-lock"
                    index={1}
                  >
                    Release Locks
                  </MenuLink>
                </HasPermission>
              </FlagadminReleaseLock>

              <HasPermission scope="admin:accounting:view">
                <MenuLink
                  as={NavLink as fixMe}
                  to="/admin/accounting/configuration"
                  rel="noopener noreferrer"
                  data-testid="nav-link-admin-accounting-default-voucher-date"
                  index={2}
                >
                  Accounting
                </MenuLink>
              </HasPermission>

              {showFuelOption && (
                <HasPermission scope="admin:accounting:view">
                  <MenuLink
                    as={NavLink as fixMe}
                    to="/admin/fuel-services/fuel-indices"
                    rel="noopener noreferrer"
                    data-testid="nav-link-admin-fuel-services"
                    index={3}
                  >
                    Fuel Services
                  </MenuLink>
                </HasPermission>
              )}
            </ToggleMenu>
          </HasPermission>
        </FlagnavAdmin>
        <HasPermission scope="accounting:view">
          {<DefaultAccountingFields />}
        </HasPermission>
        <FlagMe32174FeatAssets>
          <ToggleMenu text="Resources" id="nav-link-resources-menu">
            {(showDriverRecord ||
              showDriverTargetsAndPerformance ||
              showDriverPreferences ||
              showDriverHistory ||
              showDriverPay) && (
              <HasPermission scope="driver:view">
                <MenuLink
                  as={NavLink as fixMe}
                  to="/drivers/search"
                  rel="noopener noreferrer"
                  data-testid="nav-link-search-driver"
                  index={0}
                >
                  Driver - Search
                </MenuLink>
              </HasPermission>
            )}
            {showDriverRecord && (
              <HasPermission scope="driver:create">
                <MenuLink
                  as={NavLink as fixMe}
                  to="/drivers/add"
                  rel="noopener noreferer"
                  data-testid="nav-link-add-driver"
                  index={1}
                >
                  Driver - Add New
                </MenuLink>
              </HasPermission>
            )}
            {showTrailerRecord && (
              <HasPermission scope="trailer:view">
                <MenuLink
                  as={NavLink as fixMe}
                  to="/trailers/search"
                  rel="noopener noreferrer"
                  data-testid="nav-link-asset-trailer-search"
                  index={2}
                >
                  Trailer - Search
                </MenuLink>
              </HasPermission>
            )}
            {showTrailerRecord && (
              <HasPermission scope="trailer:create">
                <MenuLink
                  as={NavLink as fixMe}
                  to="/trailers/add"
                  rel="noopener noreferrer"
                  data-testid="nav-link-asset-trailer-add"
                  index={3}
                >
                  Trailer - Add New
                </MenuLink>
              </HasPermission>
            )}
            {showPowerRecord && (
              <HasPermission scope="power:view">
                <MenuLink
                  as={NavLink as fixMe}
                  to="/powers/search"
                  rel="noopener noreferrer"
                  data-testid="nav-link-aseet-power-view"
                  index={4}
                >
                  Power - Search
                </MenuLink>
              </HasPermission>
            )}
            {showPowerRecord && (
              <HasPermission scope="power:create">
                <MenuLink
                  as={NavLink as fixMe}
                  to="/powers/add"
                  rel="noopener noreferrer"
                  data-testid="nav-link-admin-release-lock"
                  index={5}
                >
                  Power - Add New
                </MenuLink>
              </HasPermission>
            )}
          </ToggleMenu>
        </FlagMe32174FeatAssets>
        <Grid
          css={{
            display: 'flex',
            textAlign: 'right',
            width: 'auto',
            marginLeft: 'auto',
          }}
          xs="1fr 30px"
          gap={0.5}
        >
          <div className={FS_MASK}>
            <UserNav />
          </div>
          <FlagMe172708FeatChat
            css={{ display: 'grid', placeItems: 'center', paddingRight: '5px' }}
          >
            <ChatButton />
          </FlagMe172708FeatChat>
          <div
            css={{ display: 'grid', placeItems: 'center', paddingRight: '5px' }}
          >
            <MasterFindButton />
          </div>
          <div
            css={{ display: 'grid', placeItems: 'center', paddingLeft: '5px' }}
          >
            <Button
              id={WALKME_TOGGLE_BUTTON_ID}
              fsParent="header"
              option="quaternary"
              css={{
                borderRadius: '100%',
                width: 25,
                height: 25,
                textAlign: 'center',
                lineHeight: '25px',
                padding: 0,
                minHeight: 'auto',
                fontSize: 18,
              }}
              onClick={(): void => {
                if (!USE_WALKME) {
                  systemBanner({
                    type: 'warning',
                    title: 'Walkme Disabled',
                    content:
                      'Walkme is currently disabled in this environment.',
                  });
                  return;
                }
                (document.querySelector('#walkme-player') as fixMe)?.click();
                setTimeout(() => {
                  (
                    document.querySelector('.wm-menu-search-bar input') as fixMe
                  )?.focus();
                }, 400);
              }}
            >
              ?
            </Button>
          </div>
        </Grid>
      </BaseHeader>
    </Grid>
  );
};
