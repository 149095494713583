import { useFlagMe265933HttpKnownEndpointSecurity } from '@generated/flags/ME-265933-http-known-endpoint-security';
import { useFlagMe337901AddressDisplayRemoveStringModification } from '@generated/flags/ME-337901-address-display-remove-string-modification';
import { useFlagMe34100FixCombineDateTime } from '@generated/flags/ME-34100-fix-combine-date-time';
import { useFlagMe34235AppQuietNetworkErrors } from '@generated/flags/ME-34235-app-quiet-network-errors';
import { useFlagappKeycloakMinValiditySeconds } from '@generated/flags/app-keycloak-min-validity-seconds';
import { useFlagappKeycloakTriggerRefreshSeconds } from '@generated/flags/app-keycloak-trigger-refresh-seconds';
import { useFlagappReloadOnAuthTimeout } from '@generated/flags/app-reload-on-auth-timeout';
import { useFlagappVerboseErrorDisplay } from '@generated/flags/app-verbose-error-display';
import { FC } from 'react';
import {
  ADDRESS_DISPLAY_REMOVE_STRING_MODIFICATION,
  APP_FIX_COMBINE_DATE_TIME,
  APP_HTTP_FETCH_SECURITY,
  APP_KEYCLOAK_MIN_VALIDITY_SECONDS_SYMBOL,
  APP_KEYCLOAK_TRIGGER_REFRESH_SECONDS_SYMBOL,
  APP_QUIET_NETWORK_ERRORS,
  APP_RELOAD_ON_AUTH_TIMEOUT_SYMBOL,
  APP_VERBOSE_ERROR_DISPLAY_SYMBOL,
} from './constants';
import { setGlobalVariable } from './util';

export const GlobalVariables: FC = () => {
  setGlobalVariable(
    APP_VERBOSE_ERROR_DISPLAY_SYMBOL,
    Boolean(useFlagappVerboseErrorDisplay())
  );
  setGlobalVariable(
    APP_RELOAD_ON_AUTH_TIMEOUT_SYMBOL,
    Boolean(useFlagappReloadOnAuthTimeout())
  );
  setGlobalVariable(
    APP_KEYCLOAK_TRIGGER_REFRESH_SECONDS_SYMBOL,
    useFlagappKeycloakTriggerRefreshSeconds()
  );
  setGlobalVariable(
    APP_KEYCLOAK_MIN_VALIDITY_SECONDS_SYMBOL,
    useFlagappKeycloakMinValiditySeconds()
  );
  setGlobalVariable(
    APP_QUIET_NETWORK_ERRORS,
    useFlagMe34235AppQuietNetworkErrors()
  );
  setGlobalVariable(
    APP_FIX_COMBINE_DATE_TIME,
    useFlagMe34100FixCombineDateTime()
  );
  setGlobalVariable(
    APP_HTTP_FETCH_SECURITY,
    useFlagMe265933HttpKnownEndpointSecurity()
  );
  setGlobalVariable(
    ADDRESS_DISPLAY_REMOVE_STRING_MODIFICATION,
    useFlagMe337901AddressDisplayRemoveStringModification()
  );

  return null;
};
