import { AddressDisplay } from '@components/AddressDisplay';
import {
  AutoComplete,
  Props as AutoCompleteProps,
  Shell,
} from '@components/AutoComplete';
import { useViewOnly } from '@components/ViewOnly';
import { useFlagMe156346ViewAddressCounty } from '@generated/flags/ME-156346-view-address-county';
import { useFlagMe22735FixFacilityPickerQueryLimitNumber } from '@generated/flags/ME-22735-fix-facility-picker-query-limit-number';
import { FacilityAddressBriefFragment } from '@generated/fragments/FacilityAddressBrief';
import { FacilityNoteInfoFragment } from '@generated/fragments/FacilityNoteInfo';
import { ContactInfoFragment } from '@generated/fragments/contactInfo';
import { CustomerFacilityDefaultInfoFragment } from '@generated/fragments/customerFacilityDefault';
import { CustomerFacilityDefaultInfoV2Fragment } from '@generated/fragments/customerFacilityDefaultV2';
import { FacilityIdentifierInfoFragment } from '@generated/fragments/facilityIdentifierInfo';
import { FacilityInfoFragment } from '@generated/fragments/facilityInfo';
import { FacilityInfoV2Fragment } from '@generated/fragments/facilityInfoV2';
import { KeyValueInfoFragment } from '@generated/fragments/keyValueInfo';
import { NoteInfoFragment } from '@generated/fragments/noteInfo';
import { ScheduleInfoFragment } from '@generated/fragments/scheduleInfo';
import { ScheduleInfoV2Fragment } from '@generated/fragments/scheduleInfoV2';
import {
  AllFacilitiesV2ForFacilityPickerDocument,
  AllFacilitiesV2ForFacilityPickerQuery,
  AllFacilitiesV2ForFacilityPickerQueryVariables,
} from '@generated/queries/allFacilitiesForFacilityPicker';
import { FacilityMfItemAddressFragment } from '@generated/queries/facilitiesForMasterfind';
import { FacilitiesForMasterfindV2Document } from '@generated/queries/facilitiesForMasterfindV2';
import {
  Contact,
  CustomerFacility,
  FacilityLoadDefaults,
} from '@generated/types';
import { useDebouncedFn } from '@hooks/useDebouncedFn';
import { useLazyQueryWithDataPromise } from '@hooks/useLazyQueryWithDataPromise';
import { getNodesFromConnection } from '@utils/graphqlUtils';
import { pipeSeparator } from '@utils/htmlEntities';
import { filterByPromiseFulfilled } from '@utils/promise';
import { compact, uniqBy } from 'lodash-es';
import {
  FC,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';

export type BaseFacilityAddress = FacilityMfItemAddressFragment;

type BaseFacilityContact = Pick<
  Contact,
  'active' | 'chatType' | 'id' | 'main' | 'name'
>;

export type FacilityBrief = Pick<
  FacilityInfoV2Fragment,
  'id' | 'name' | 'code' | 'facilityType' | 'phoneNumber' | 'mainAddress'
>;

export type BaseFacility = FacilityBrief & {
  addresses: ReadonlyArray<BaseFacilityAddress>;
  contacts: ReadonlyArray<BaseFacilityContact>;
  customerFacilities: Maybe<ReadonlyArray<CustomerFacility>>;
  customerFacilityInfo: Maybe<Readonly<Partial<CustomerFacility>>>;
  facilityType: Maybe<Readonly<KeyValueInfoFragment>>;
  isCustomerFacility: Maybe<Readonly<boolean>>;
  mainAddress: Maybe<BaseFacilityAddress>;
  phoneNumber: Maybe<Readonly<string>>;
  [index: string]: fixMe;
};

export type BaseFacilityShell = Shell<BaseFacility>;

export type ExtendedAddress = Omit<
  FacilityAddressBriefFragment,
  '__typename' | 'facililityId' | 'isVerified'
> & {
  facililityId?: string;
};

export interface ExtendedFacility
  extends Pick<
    FacilityInfoFragment | FacilityInfoV2Fragment,
    | 'id'
    | 'name'
    | 'code'
    | 'facilityNote'
    | 'facilityType'
    | 'loadFromType'
    | 'phoneNumber'
    | 'scaleNote'
    | 'schedulingSystemType'
    | 'schedulingContact'
    | 'sourceType'
    | 'status'
    | 'timezone'
    | 'taxExempt'
    | 'unloadFromType'
  > {
  addresses?: Maybe<ReadonlyArray<ExtendedAddress>>;
  mainAddress?: Maybe<BaseFacilityAddress | ExtendedAddress>;
  contacts: ReadonlyArray<Omit<ContactInfoFragment, '__typename'>>;
  externalNotes: Maybe<
    | ReadonlyArray<Omit<NoteInfoFragment, '__typename'>>
    | ReadonlyArray<Omit<FacilityNoteInfoFragment, '__typename'>>
  >;
  facilityIdentifiers: Maybe<
    ReadonlyArray<Omit<FacilityIdentifierInfoFragment, '__typename'>>
  >;
  notes: Maybe<
    | ReadonlyArray<Omit<NoteInfoFragment, '__typename'>>
    | ReadonlyArray<Omit<FacilityNoteInfoFragment, '__typename'>>
  >;
  schedules:
    | ReadonlyArray<Omit<ScheduleInfoFragment, '__typename'>>
    | ReadonlyArray<Omit<ScheduleInfoV2Fragment, '__typename'>>;
  customerFacilities: Maybe<
    ReadonlyArray<
      | CustomerFacilityDefaultInfoFragment
      | CustomerFacilityDefaultInfoV2Fragment
    >
  >;
  isCustomerFacility: Maybe<Readonly<boolean>>;
  facilityLoadDefaults: Maybe<FacilityLoadDefaults>;
  customerFacilityInfo: Maybe<Readonly<Partial<CustomerFacility>>>;
}

export interface FacilityPickerProps<ItemType>
  extends Omit<AutoCompleteProps<ItemType>, 'items'> {
  /** Initial facility code, if you want the component to do a lookup */
  initialCode?: Maybe<string>;
  ['data-testid']?: string;
  /** Typical workflows should only search for facilities that are NOT duplicates, and have an "active" status. However, if you need to include all facilities no matter the status, use "all". */
  include: 'only-active' | 'all';
  filterItems?: (items: ReadonlyArray<ItemType>) => ReadonlyArray<ItemType>;
  /** Choose which fragment will be used to resolve the Facility. Small is only necessary information like name and id. Large includes many more keys, like contacts and should be used with caution to avoid API pressure. */
  itemFidelity?: 'small' | 'large';
  /** Initial customer id, if you want the component to do a lookup from customer facility relationship */
  initialCustomerId?: Maybe<string>;
  showCounty?: boolean;
  showCountry?: boolean;
}

export const toBaseFacilityShell = (
  value: BaseFacility
): BaseFacilityShell => ({
  value,
  label: value.name,
  id: value.id,
});

export const FacilityPickerItemDisplay: FC<{
  facility: Maybe<FacilityBrief>;
  compact?: boolean;
  showCounty?: boolean;
  showCountry?: boolean;
}> = ({
  facility,
  compact: compactProp,
  showCounty = false,
  showCountry = false,
}) => {
  const useAddressCounty = useFlagMe156346ViewAddressCounty();
  if (!facility) {
    return <></>;
  }
  const { name, code, mainAddress } = facility;
  return (
    <div>
      <strong>{name}</strong>
      {pipeSeparator}
      <span>{code}</span>
      {compactProp ? <br /> : pipeSeparator}
      <AddressDisplay
        value={mainAddress}
        street
        city
        state
        county={useAddressCounty && showCounty}
        country={showCountry}
      />
    </div>
  );
};

// Ideally we could use one query to search by name OR code, but the API does not support that right now
// So we execute three queries and combine them ourselves two for name field with and without number and one for code
export function useFacilityCombinedSearch<T extends FacilityBrief>(kwargs: {
  large?: boolean;
  filterInactive: boolean;
  customerIds?: string[];
}): (text: string, kwargs?: { codeOnly?: boolean }) => Promise<T[]> {
  const { filterInactive, large, customerIds } = kwargs;
  const document = large
    ? AllFacilitiesV2ForFacilityPickerDocument
    : FacilitiesForMasterfindV2Document;

  const callQueryV2 = useLazyQueryWithDataPromise<
    AllFacilitiesV2ForFacilityPickerQuery,
    AllFacilitiesV2ForFacilityPickerQueryVariables
  >(document);

  const callQuery = callQueryV2;

  const first = useFlagMe22735FixFacilityPickerQueryLimitNumber() || 10;

  return useCallback(
    async (text: string): Promise<T[]> => {
      // If we are using our client-side filtering, we need to only send the facility name to the query. So we strip the address off.
      // ie "General Mills 200 main street" becomes "General Mills"
      // Of course this is not completely adequate, as the user can type anything into the input, but hopefully it is good enough until we come up with better API-specific solutions.
      // This if/else block is just to merge into release branch safely without breaking anything. Once that is done it can be removed.
      const rawRes = await Promise.allSettled(
        compact([
          callQuery({
            fetchPolicy: 'network-only',
            variables: {
              first,
              filter: { text: text, useFacilityPickerFuzzy: true },
              ...(customerIds && customerIds.length > 0
                ? { customerIds: customerIds }
                : {}),
            },
          }),
        ])
      );
      const resultsCombined = uniqBy(
        filterByPromiseFulfilled(rawRes)
          .map((obj) => {
            return getNodesFromConnection(obj.value.data.allFacilitiesV2);
          })
          .flat(),
        (obj) => obj.id
      ).filter((obj) => {
        if (filterInactive) {
          return !obj.status?.toLowerCase().match(/inactive|duplicate/);
        }
        return true;
      });

      return resultsCombined as fixMe;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [callQuery]
  );
}
interface StampedResultSet {
  facilities: BaseFacility[];
  searchKey: string;
}
export const FacilityPicker = <PickerType extends BaseFacility>({
  initialCode,
  ['data-testid']: testId,
  disabled,
  selectedItem,
  inputProps,
  onChange,
  include: mode,
  filterItems,
  itemFidelity,
  showCounty = false,
  showCountry = false,
  ...restProps
}: FacilityPickerProps<PickerType>): ReactElement => {
  const shouldUseLarge = itemFidelity === 'large';
  const { isViewOnly } = useViewOnly();
  const searchFacilities = useFacilityCombinedSearch<PickerType>({
    large: shouldUseLarge,
    filterInactive: mode === 'only-active',
  });

  const initialState = {
    searchKey: '',
    facilities: [],
  };
  const [dataResults, setDataResults] =
    useState<StampedResultSet>(initialState);
  const [loading, setLoading] = useState(false);

  const [dropdownKey, setDropdownKey] = useState(Date.now());

  const [shouldUseInitialFacility, setShouldUseInitialFacility] = useState(
    Boolean(initialCode)
  );

  useEffect((): void => {
    (async (): Promise<void> => {
      if (initialCode && shouldUseInitialFacility) {
        setLoading(true);
        const res = await searchFacilities(initialCode, { codeOnly: true });
        setDataResults({ facilities: res, searchKey: '' });
      } else {
        setDataResults(initialState);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldUseInitialFacility]);
  const shouldFilter = useFlagMe22735FixFacilityPickerQueryLimitNumber() !== 0;

  const [searchStr, setSearchStr] = useState('');

  const get = useCallback(
    async (str: string): Promise<void> => {
      if (!str.length) {
        setDataResults(initialState);
        return;
      }
      const searchStr = str.trim();
      setSearchStr(str);
      if (searchStr.length > 2) {
        setLoading(true);
        const res = await searchFacilities(searchStr);
        setDataResults({ facilities: res, searchKey: str });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchFacilities, initialState, searchStr, dataResults]
  );
  const onInputValueChange = useDebouncedFn(get, 400, []);

  let gotFacilityFromInitialCode = false;
  let initialSelectedItem: Maybe<Shell<BaseFacility>> = undefined;
  if (initialCode) {
    initialSelectedItem = dataResults.facilities
      .filter((obj) => obj.code === initialCode)
      .map(toBaseFacilityShell)[0];
    if (initialSelectedItem) {
      gotFacilityFromInitialCode = true;
    }
  }

  useEffect(() => {
    if (shouldUseInitialFacility) {
      setDropdownKey(Date.now());
    }
  }, [
    gotFacilityFromInitialCode,
    shouldUseInitialFacility,
    initialSelectedItem?.value?.name,
  ]);
  const [items, setItems] = useState<ReadonlyArray<BaseFacilityShell>>([]);
  useEffect(() => {
    if (searchStr == dataResults.searchKey) {
      const items: ReadonlyArray<BaseFacilityShell> =
        dataResults.facilities.map((obj) => {
          return {
            label: (item: BaseFacility): ReactNode => (
              <FacilityPickerItemDisplay
                facility={item}
                showCounty={showCounty}
                showCountry={showCountry}
              />
            ),
            value: obj,
            id: obj?.id,
          };
        }) || [];

      setItems(items);
      setLoading(false);
    } else if (searchStr.length <= 2 && !shouldUseInitialFacility) {
      setItems([]);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataResults, searchStr, shouldFilter, shouldUseInitialFacility]);

  return isViewOnly ? (
    <div data-testid={testId || restProps.name}>{selectedItem?.label}</div>
  ) : (
    <AutoComplete<PickerType>
      fsName="facility-search"
      fsParent="facility"
      key={dropdownKey}
      data-testid={testId}
      disabled={disabled}
      loading={loading}
      onChange={(item, helpers): void => {
        setShouldUseInitialFacility(false);
        onChange && onChange(item, helpers);
      }}
      onInputValueChange={(str): void => {
        setShouldUseInitialFacility(false);
        onInputValueChange(str);
      }}
      items={filterItems ? filterItems(items as fixMe) : (items as fixMe)}
      inputProps={{
        placeholder: 'Search Facilities',
        ...inputProps,
      }}
      selectedItem={selectedItem}
      initialSelectedItem={initialSelectedItem as fixMe}
      {...restProps}
    />
  );
};

export const FacilityPickerLarge = (
  props: FacilityPickerProps<ExtendedFacility>
): ReactElement => {
  return <FacilityPicker<anyOk> {...props} itemFidelity="large" />;
};
