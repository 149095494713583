import { SidebarContext } from '@app/Sidebar/util';
import { TCNameEnum, useTenantConfig } from '@app/TenantConfig';
import { AutoComplete } from '@components/AutoComplete';
import { FC, useContext } from 'react';
import { SIDEBAR_SEARCH_STYLE } from '../style';

interface GroupSearchProps {
  placeholder?: string;
  showSearchIcon?: boolean;
}

export const GroupSearch: FC<GroupSearchProps> = ({
  placeholder,
  showSearchIcon,
}) => {
  const { setGroup } = useContext(SidebarContext);
  const employeeGroupList = useTenantConfig(
    TCNameEnum.EmployeeGroup
  ).activeDropdownItems;
  return (
    <>
      <AutoComplete
        fsName="group-search"
        fsParent="sidebar"
        showSearchIcon={showSearchIcon ?? true}
        filterOnLabel
        items={employeeGroupList}
        onChange={(selected): void => {
          if (selected?.value?.id) {
            setGroup({
              id: selected?.value?.id || '',
              groupName: selected?.value?.name || '',
            });
          }
        }}
        css={{ height: '100%' }}
        renderDropdownInPopper
        inputProps={{
          placeholder: placeholder ?? 'Group Search',
          'data-testid': 'group-search-input',
          css: SIDEBAR_SEARCH_STYLE,
        }}
      />
    </>
  );
};
