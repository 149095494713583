import { TableId } from '@components/Table';
import { tablePrefsLoadedAtom } from '@components/Table/TablePreferenceInitializer';
import { TablePreferencesSettings } from '@components/Table/util/customizeTable';
import { useFlagMe269599EnableTableCustomizationSettingsMigration } from '@generated/flags/ME-269599-enable-table-customization-settings-migration';
import { useCreateOrUpdateTablePreferenceMutation } from '@generated/mutations/createOrUpdateTablePreference';
import { useGetBulkTablePreferenceLazyQuery } from '@generated/queries/getBulkTablePreference';
import {
  TableFieldsInput,
  TablePreferenceResponseType,
} from '@generated/types';
import { useIncrease } from '@hooks/useIncrease';
import { useIndexedDBStore } from '@hooks/useIndexedDB';
import { TABLE_PREFERENCES_IDB_STORE } from '@hooks/useIndexedDB/config';
import { reportCustomSentryError } from '@utils/sentry';
import { useAtom } from 'jotai';
import { isEmpty } from 'lodash-es';
import { useEffect, useState } from 'react';
import { useMount } from 'react-use';
import {
  idbDeleteAllPrefs,
  idbGetAllPrefs,
  idbHasPrefsForTable,
  idbSavePrefsForTable,
} from './idb';
import { convertPrefsToDbFormat, getFormattedPrefs } from './util';

export interface SingleTablePreference {
  tableId: string;
  tableName: string;
  tableFields: Partial<TablePreferencesSettings>[];
}

export type AllTablePreferences = Record<TableId, SingleTablePreference>;

interface TablePreferencesActions {
  /** Update preferences for a single Table component. */
  setTablePrefs: (
    tablePreferences: SingleTablePreference,
    isReset?: boolean
  ) => void;
  /** Has the hook fetched cached preferences from local IndexedDB? */
  hasFetchedFromLocal: boolean;
  /** Has the hook fetched the latest preferences from the API? */
  hasFetchedFromApi: boolean;
}

export interface TablePreferencesShape {
  objectId: string;
  tableName: string;
  columns: TablePreferencesSettings[];
}

/** useTablePreference hook */
export function useTablePreference(
  tableId?: string,
  /** Is this the initializer that is mounted a single time for the whole app? */
  initializer?: boolean
): [AllTablePreferences, TablePreferencesActions] {
  const enableTableCustomizationMigration =
    useFlagMe269599EnableTableCustomizationSettingsMigration();

  const idb = useIndexedDBStore(TABLE_PREFERENCES_IDB_STORE);

  const [hasBulkFetchedFromIdb, setHasBulkFetchedFromIdb] =
    useState<boolean>(false);

  // Global flag that bulk query has completed. Set in initializer hook and later read by table component hooks.
  const [prefsLoadedAtom, setPrefsLoadedAtom] = useAtom(tablePrefsLoadedAtom);

  const [isPrefsLoading, setPrefsLoading] = useState<boolean>(false);
  const [allTablePreferences, setAllTablePreferences] =
    useState<AllTablePreferences>({});

  const [refetchCount, bumpRefetchCount] = useIncrease();

  const addOrUpdateSinglePrefInIndexDB = async (
    pref: TablePreferencesShape
  ): Promise<void> => {
    const exists = idbHasPrefsForTable(pref.objectId, allTablePreferences);

    // Only sent to IDB if it already exists or we have everything we need for a complete entry.
    if (exists || (pref.objectId && pref.columns)) {
      const addedPref = await idbSavePrefsForTable(idb, pref, !exists);
      const formattedPrefs = getFormattedPrefs([addedPref]);
      setAllTablePreferences((oldPrefs) => ({
        ...oldPrefs,
        ...formattedPrefs,
      }));
    }
  };

  // Query to fetch table preferences for all tables from API.
  //  1. Fetch all table preferences from the API.
  //  2. Store all preferences in IDB.
  //  3. Pass the new preferences out of the hook to the table component.
  const [getBulkTablePreferences, { refetch }] =
    useGetBulkTablePreferenceLazyQuery({
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
      onCompleted: async (bulkPrefRes) => {
        setPrefsLoading(false);
        setPrefsLoadedAtom(true);

        try {
          const updatedPrefs = bulkPrefRes?.getBulkTablePreference
            ? convertPrefsToDbFormat(
                bulkPrefRes?.getBulkTablePreference as Partial<TablePreferenceResponseType>[]
              )
            : [];

          const formattedPrefs = getFormattedPrefs(updatedPrefs);

          setAllTablePreferences((oldPrefs) => ({
            ...oldPrefs,
            ...formattedPrefs,
          }));

          // Update IndexedDB with the new preferences
          if (updatedPrefs?.length) {
            await Promise.all(
              updatedPrefs.map(async (pref) => {
                await addOrUpdateSinglePrefInIndexDB(pref);
              })
            );
          }
        } catch (err) {
          reportCustomSentryError(
            `Error bulk updating table preferences: ${err}`
          );
        }
      },
      onError: async (error) => {
        reportCustomSentryError(
          `Error bulk fetching table preferences: ${error}`
        );
        // Retry the query
        try {
          bumpRefetchCount();
          if (refetchCount < 3) {
            await refetch();
          } else {
            reportCustomSentryError(`Table preferences - hit refetch limit`);
          }
        } catch (refetchError) {
          reportCustomSentryError(
            `Error refetching table preferences: ${refetchError}`
          );
        }
      },
    });

  // Mutation to create or update preferences for a single table.
  const [createOrUpdateTablePreferences] =
    useCreateOrUpdateTablePreferenceMutation({
      onCompleted: async (res) => {
        const updatedPrefs = res?.createOrUpdateTablePreference
          ? convertPrefsToDbFormat([
              res?.createOrUpdateTablePreference,
            ] as Partial<TablePreferenceResponseType>[])
          : [];

        if (enableTableCustomizationMigration && updatedPrefs?.length) {
          try {
            const pref = updatedPrefs[0] as TablePreferencesShape;
            await addOrUpdateSinglePrefInIndexDB(pref);
          } catch (error) {
            reportCustomSentryError(
              `Error while add Or Update table Preferences in InIndexDB: ${error}`
            );
          }
        }
      },
      onError: (error) => {
        reportCustomSentryError(
          `Error bulk update table preferences: ${error}`
        );
      },
    });

  useMount(() => {
    if (initializer) {
      if (enableTableCustomizationMigration) {
        // Refresh IDB from API in the global initializer, on page load.
        setPrefsLoading(true);
        getBulkTablePreferences();
      } else {
        // Clean up IDB when FF is off.
        idbDeleteAllPrefs(idb);
      }
    }
  });

  // Load table preferences from IndexedDB.
  useEffect(() => {
    const fetchPreferencesFromDB = async (): Promise<void> => {
      try {
        if (
          enableTableCustomizationMigration &&
          !hasBulkFetchedFromIdb &&
          !isPrefsLoading
        ) {
          const allPrefs = await idbGetAllPrefs(idb);
          setAllTablePreferences((oldPrefs) => ({
            ...oldPrefs,
            ...allPrefs,
          }));

          if (!hasBulkFetchedFromIdb) {
            setHasBulkFetchedFromIdb(true);
          }
        }
      } catch (err) {
        reportCustomSentryError(
          `Error fetching table preferences in from indexDB | ${err}`
        );
      }
    };
    fetchPreferencesFromDB();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    hasBulkFetchedFromIdb,
    allTablePreferences,
    enableTableCustomizationMigration,
  ]);

  // Called by Table component to trigger prefs update.
  const setTablePrefs = async (
    tablePreferences: SingleTablePreference,
    isReset?: boolean
  ): Promise<void> => {
    const tableIdForPref = tablePreferences?.tableId;
    const tableFieldValues = tablePreferences?.tableFields;

    const tableprefsForDB = [
      {
        objectId: tablePreferences?.tableId,
        columns: tableFieldValues,
        tableName: tablePreferences?.tableName,
      },
    ];

    if (isReset) {
      setAllTablePreferences((oldPrefs) => ({
        ...oldPrefs,
        [tableIdForPref]: tablePreferences,
      }));

      await addOrUpdateSinglePrefInIndexDB(tableprefsForDB as fixMe);
    }

    try {
      if (!isEmpty(tableFieldValues)) {
        await createOrUpdateTablePreferences({
          variables: {
            input: {
              objectId: tableIdForPref || '',
              tableFields: tableFieldValues as TableFieldsInput[],
              tableName: tableIdForPref || '',
            },
          },
        });
      } else {
        await addOrUpdateSinglePrefInIndexDB(tableprefsForDB as fixMe);
      }
    } catch (error) {
      reportCustomSentryError(
        `Error while setting table preferences: ${error}`
      );
    }
  };

  return [
    allTablePreferences,
    {
      setTablePrefs,
      hasFetchedFromLocal: hasBulkFetchedFromIdb,
      hasFetchedFromApi: prefsLoadedAtom,
    },
  ];
}
