import { ADDRESS_DISPLAY_REMOVE_STRING_MODIFICATION } from '@app/GlobalVariables/constants';
import { getGlobalVariable } from '@app/GlobalVariables/util';
import { AddressBriefFragment } from '@generated/fragments/addressBrief';
import { AddressInfoFragment } from '@generated/fragments/addressInfo';
import { CarrierAddressBriefFragment } from '@generated/fragments/carrierAddressBrief';
import { CustomerAddressBriefV2Fragment } from '@generated/fragments/customerAddressBriefV2';
import { CustomerAddressInfoV2Fragment } from '@generated/fragments/customerAddressInfoV2';
import { FacilityAddressBriefFragment } from '@generated/fragments/FacilityAddressBrief';
import { StopAddress, StopAddressV2 } from '@generated/types';
import { titleCaseNoStrip } from '@utils/titleCaseNoStrip';
import { compact } from 'lodash-es';
import { FC } from 'react';

export enum SupportedCountries {
  'US' = 'US',
  'MX' = 'MX',
  'CA' = 'CA',
}

export const COUNTRY_OPTIONS: Record<
  string,
  { text: string; abbr: keyof typeof SupportedCountries }
> = {
  usa: { text: 'USA', abbr: 'US' },
  mexico: { text: 'Mexico', abbr: 'MX' },
  canada: { text: 'Canada', abbr: 'CA' },
};

export interface Props {
  value?:
    | Maybe<Partial<AddressInfoFragment>>
    | Maybe<Partial<AddressBriefFragment>>
    | Maybe<Partial<StopAddress>>
    | Maybe<Partial<CarrierAddressBriefFragment>>
    | Maybe<Partial<CustomerAddressInfoV2Fragment>>
    | Maybe<Partial<CustomerAddressBriefV2Fragment>>
    | Maybe<Partial<FacilityAddressBriefFragment>>
    | Maybe<Partial<StopAddressV2>>;
  /** Use newlines for display instead of a single comma separated string */
  newLines?: boolean;
  /** If provided, only render parts of address provided in props */
  street?: boolean;
  city?: boolean;
  state?: boolean;
  postalCode?: boolean;
  country?: boolean;
  addressSuggestion?: boolean;
  useCityStateStopOnLoad?: boolean;
  county?: boolean;
}

const titleCaseWithAddressFixes = (str: Maybe<string>): string => {
  if (!str) {
    return '';
  }
  return titleCaseNoStrip(str).replace(
    // replace strings like Po with PO and Ne with NE etc
    /\b(?:Po|Ne|Se|Sw|Nw)\b/g,
    (match) => match.toUpperCase()
  );
};

const stringModifier = (str: Maybe<string>): string => {
  const removeModFlag = getGlobalVariable(
    ADDRESS_DISPLAY_REMOVE_STRING_MODIFICATION
  );
  if (removeModFlag) {
    return titleCaseWithAddressFixes(str);
  }
  return titleCaseNoStrip(str);
};

const getAddressDisplayParts = (props: Props): string[] => {
  const {
    value,
    street,
    city,
    state,
    postalCode,
    country,
    useCityStateStopOnLoad,
    county,
  } = props;
  if (!value) {
    return [];
  }
  const showAll = !street && !city && !state && !postalCode && !country;
  const showStreet = showAll || street;

  const statePostalCodeParts = compact([
    (showAll || state) && value.state,
    (showAll || postalCode) && value.postalCode,
  ]);

  const cityStatePostalCodeParts = compact([
    (showAll || city) && stringModifier(value.city),
    statePostalCodeParts.join(' '),
  ]);

  const countryPart =
    (showAll || country) &&
    value.country &&
    (COUNTRY_OPTIONS[value.country.toLowerCase()]?.text ?? value.country);

  const street1Part = useCityStateStopOnLoad
    ? value.street1
      ? showStreet && stringModifier(value.street1)
      : ''
    : showStreet && stringModifier(value.street1);

  const countyPart =
    county && 'county' in value && value.county
      ? stringModifier(`${value.county}`)
      : '';

  const parts = compact([
    street1Part,
    showStreet && value.street2,
    cityStatePostalCodeParts.join(', '),
    countryPart,
    countyPart,
  ]);

  return parts;
};

export const getAddressDisplayString = (props?: Maybe<Props>): string => {
  if (!props) {
    return '';
  }
  if (props?.addressSuggestion) {
    return getAddressDisplayParts(props).slice(1).join(', ');
  }
  return getAddressDisplayParts(props).join(', ');
};

export const AddressDisplay: FC<Props> = (props) => {
  const {
    value,
    newLines,
    addressSuggestion,
    // stripping out props we do not want to pass to the html element
    /* eslint-disable @typescript-eslint/no-unused-vars */
    street,
    city,
    state,
    postalCode,
    country,
    useCityStateStopOnLoad,
    county,
    /* eslint-enable @typescript-eslint/no-unused-vars */
    ...rest
  } = props;

  if (!value) {
    return null;
  }

  if (newLines) {
    const parts = getAddressDisplayParts(props);
    return (
      <address {...rest}>
        {parts.map((str, idx) => (
          <div key={idx}>{str}</div>
        ))}
      </address>
    );
  }

  if (addressSuggestion) {
    return (
      <address {...rest}>
        {value.street1},<br />
        {getAddressDisplayString(props)}
      </address>
    );
  }
  return <address {...rest}>{getAddressDisplayString(props)}</address>;
};
