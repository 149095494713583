import { StatusTypeEnum } from '@generated/types';

export const STATUS_OPTIONS: Partial<Record<StatusTypeEnum, { text: string }>> =
  {
    newAccount: { text: 'New' },
    new: { text: 'New' },
    pending: { text: 'Pending' },
    approved: { text: 'Approved' },
    denied: { text: 'Denied' },
    hold: { text: 'Hold' },
    selective: { text: 'Selective' },
    not_monitored: { text: 'Not Monitored' },
  };

export const EXTERNAL_STATUS_OPTIONS: Partial<
  Record<StatusTypeEnum, { text: string }>
> = {
  unknown: { text: 'Unknown' },
  newAccount: { text: 'New' },
  new: { text: 'New' },
  pending: { text: 'Pending' },
  approved: { text: 'Approved' },
  denied: { text: 'Denied' },
  hold: { text: 'Hold' },
  selective: { text: 'Selective' },
  not_monitored: { text: 'Not Monitored' },
};
