import * as TP from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetBulkTablePreferenceQueryVariables = TP.Exact<{ [key: string]: never; }>;


export type GetBulkTablePreferenceQuery = (
  { readonly __typename?: 'Query' }
  & { readonly getBulkTablePreference: ReadonlyArray<(
    { readonly __typename?: 'TablePreferenceResponseType' }
    & Pick<TP.TablePreferenceResponseType, '_id' | 'tableName' | 'createdAt' | 'lastModified' | 'createdBy' | 'objectId'>
    & { readonly tableFields: ReadonlyArray<(
      { readonly __typename?: 'TableFields' }
      & Pick<TP.TableFields, 'name' | 'id' | 'isVisible' | 'sort' | 'width'>
    )> }
  )> }
);


export const GetBulkTablePreferenceDocument = gql`
    query GetBulkTablePreference {
  getBulkTablePreference {
    _id
    tableName
    tableFields {
      name
      id
      isVisible
      sort
      width
    }
    createdAt
    lastModified
    createdBy
    objectId
  }
}
    `;

/**
 * __useGetBulkTablePreferenceQuery__
 *
 * To run a query within a React component, call `useGetBulkTablePreferenceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBulkTablePreferenceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBulkTablePreferenceQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBulkTablePreferenceQuery(baseOptions?: Apollo.QueryHookOptions<GetBulkTablePreferenceQuery, GetBulkTablePreferenceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBulkTablePreferenceQuery, GetBulkTablePreferenceQueryVariables>(GetBulkTablePreferenceDocument, options);
      }
export function useGetBulkTablePreferenceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBulkTablePreferenceQuery, GetBulkTablePreferenceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBulkTablePreferenceQuery, GetBulkTablePreferenceQueryVariables>(GetBulkTablePreferenceDocument, options);
        }
export type GetBulkTablePreferenceQueryHookResult = ReturnType<typeof useGetBulkTablePreferenceQuery>;
export type GetBulkTablePreferenceLazyQueryHookResult = ReturnType<typeof useGetBulkTablePreferenceLazyQuery>;
export type GetBulkTablePreferenceQueryResult = Apollo.QueryResult<GetBulkTablePreferenceQuery, GetBulkTablePreferenceQueryVariables>;