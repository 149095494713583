import { ThemeColor } from '@emotion/react';
import { appThemeContract } from './pallette.css';

/** @deprecated You should not use this object directly. Use the `@hooks/useTheme` React hook. */
export const PRIVATE_colors: Record<ThemeColor, string> = {
  error: appThemeContract.error[40],
  errorLight: appThemeContract.error[95],
  primary: appThemeContract.primary[40],
  primaryLight: appThemeContract.primary[95],
  primaryDark: appThemeContract.primary[30],
  success: appThemeContract.success[40],
  successLight: appThemeContract.success[95],
  text: appThemeContract.gray[30],
  textMuted: appThemeContract.neutral[70],
  textReverse: appThemeContract.gray.white,
  warning: appThemeContract.warning[50],
  warningLight: appThemeContract.warning[95],
  tentative: appThemeContract.warning[50],
  warningDark: appThemeContract.warning[40],
  darkPurple: appThemeContract.violet[40],
  palePurple: appThemeContract.violet[95],
  // eslint-disable-next-line mastery/named-colors
  // black: '#000000',
  lightCyan: appThemeContract.cyan[50],
  disabledText: appThemeContract.neutral[70],
};

// Rename to colors when we're ready to delete the old colors.
export const newColors = {
  system: {
    modal: {
      underlay: `${appThemeContract.neutral[10]}80`,
    },
    error: {
      background: appThemeContract.error[95],
      accent: appThemeContract.error[40],
      title: appThemeContract.error[40],
      text: appThemeContract.error[10],
      tonal: appThemeContract.error[90],
    },
    warning: {
      background: appThemeContract.warning[95],
      accent: appThemeContract.warning[50],
      title: appThemeContract.warning[40],
      text: appThemeContract.warning[10],
      tonal: appThemeContract.warning[90],
    },
    success: {
      background: appThemeContract.success[95],
      accent: appThemeContract.success[40],
      title: appThemeContract.success[40],
      text: appThemeContract.success[10],
      tonal: appThemeContract.success[90],
    },
    info: {
      background: appThemeContract.primary[95],
      accent: appThemeContract.primary[40],
      title: appThemeContract.primary[40],
      text: appThemeContract.primary[10],
      tonal: appThemeContract.primary[90],
    },
  },
  text: {
    black: appThemeContract.neutral[10],
    white: appThemeContract.neutral.white,
  },
};
