import { parseAbsolute } from '@internationalized/date';
import { IANATimezones } from '@utils/time';
import { addYearsToZoned, startOfDay } from '@utils/time/util';
import { isNumber } from 'lodash-es';

export const COMPONENT_DATEPICKER = 'date-picker';

export const dateNeedsModernYearCoercion = (rawVal: Date): boolean => {
  const year = rawVal?.getFullYear();
  return isNumber(year) && year < 100;
};

export const coerceDateToModernYear = (
  rawVal: Date,
  timezone: IANATimezones
): Date => {
  if (dateNeedsModernYearCoercion(rawVal)) {
    const yearsToAdd = Math.floor(new Date().getFullYear() / 100) * 100;

    const parsed = addYearsToZoned(
      parseAbsolute(rawVal.toISOString(), timezone),
      yearsToAdd
    );

    return startOfDay(parsed).toDate();
  }
  return rawVal;
};
