import { useHasPermissionFromContext } from '@components/PermissionsContext';
import { usePhoneLib } from '@components/Phone/PhoneValue';
import { omit } from 'lodash-es';
import { FC, ReactElement, ReactNode, useRef, useState } from 'react';
import { SetOptional } from 'type-fest';
import { Field, FormGroupProps } from '..';
import {
  DEFAULT_COUNTRY,
  Props as InputProps,
  PhoneInput,
  coerceToDefaultCountry,
} from '../../Phone/PhoneInput';
import { LockedField, Props as LockedFieldProps } from '../LockedField';

// ts-unused-exports:disable-next-line
export interface Props
  extends Omit<
      FormGroupProps<string>,
      | 'checkboxSize'
      | 'onChange'
      | 'value'
      | 'onKeyDown'
      | 'onFocus'
      | 'onBlur'
      | 'children'
    >,
    Omit<SetOptional<InputProps, 'onChange'>, 'name'>,
    Pick<LockedFieldProps, 'initialLocked' | 'lockable' | 'initialAutofocus'> {
  //
}

export const lockMask = (s: Maybe<string>): string =>
  s ? `***${s.replace(/\D/gi, '').slice(-4)}` : '';

const truthy = (): boolean => true;

export const PhoneField: FC<Props> = (rawProps) => {
  const phoneLib = usePhoneLib();
  const isValid = phoneLib ? phoneLib.isValidPhoneNumber : truthy;
  const [userCanEdit] = useHasPermissionFromContext();
  const { lockable = false, validate, initialAutofocus, ...props } = rawProps;
  const hasFocused = useRef(false);
  const [validateNum, setValidateNum] = useState(0);
  const runValidation = async (
    val: typeof rawProps.value
  ): Promise<Maybe<string | void>> => {
    const coercedToCountry = coerceToDefaultCountry({
      phoneLib,
      defaultCountry: props.defaultCountry ?? DEFAULT_COUNTRY,
      value: val,
    });
    const pass = isValid(coercedToCountry);
    if (!pass && val) {
      return 'Invalid number';
    } else if (validate) {
      return validate(val);
    }
  };
  return (
    <Field<string>
      {...omit(props, ['onChange', 'onBlur', 'onFocus', 'onKeyDown'])}
      validate={(val): fixMe => {
        // this is a hack to force the PhoneInput field to retrigger its onChange that sets the default country code
        // we do this because in some instances where Formik is used, the enableReinitialize prop is present, and the initialValues are being updated after the initial render
        // when this happens, the altered phone number with default country code is not being set properly in the formik state
        // the real fix is to formulate the initialValues properly _before_ rendering the formik component, but that is on consumers to set up properly.
        // See ME-271816
        if (!hasFocused.current) {
          setTimeout(() => {
            setValidateNum((n) => n + 1);
          }, 0);
        }
        return runValidation(val);
      }}
    >
      {({ renderLabel, ...rest }): ReactElement => {
        return (
          <div>
            {renderLabel(rest.field)}
            <LockedField
              onLockChange={(): void => {
                rest.form.setFieldValue(props.name, null);
              }}
              lockedInputProps={{ value: lockMask(rest.field.value) }}
              lockable={lockable}
              initialAutofocus={initialAutofocus}
              readOnly={props.readOnly}
            >
              {({ fieldProps }): ReactNode => (
                <PhoneInput
                  internalOnChangeKey={validateNum}
                  readOnly={!userCanEdit}
                  {...props}
                  {...rest.field}
                  hideCountryPicker={props.hideCountryPicker || lockable}
                  // For some reason rest.field.onChange isn't working...
                  // Using rest.form.setFieldValue instead
                  onFocus={() => {
                    if (!hasFocused.current) {
                      hasFocused.current = true;
                    }
                  }}
                  onChange={async (e): Promise<void> => {
                    // avoid issue like https://masterysys.atlassian.net/browse/ME-346188
                    // the formik instance is becoming dirty when the field starts as null
                    if (rest.field.value === null && e === '') {
                      return;
                    }
                    if (!props.onChange) {
                      rest.form.setFieldValue(
                        props.name,
                        e || '',
                        // we need to use false if the field has never been focused, as that will avoid a validation + onChange loop from the validation function above
                        hasFocused.current ? true : false
                      );
                    } else {
                      props.onChange(e);
                    }
                  }}
                  id={rest.htmlFor}
                  {...fieldProps({
                    onBlur: (e): void => {
                      if (!props.onBlur) {
                        return rest.form.setFieldTouched(props.name, true);
                      }
                      props.onBlur(e);
                    },
                  })}
                />
              )}
            </LockedField>
          </div>
        );
      }}
    </Field>
  );
};
