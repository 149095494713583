import { AddressDisplay } from '@components/AddressDisplay';
import {
  AutoComplete,
  Props as AutoCompleteProps,
  Shell,
} from '@components/AutoComplete';
import { Label } from '@components/FieldLabel';
import { useViewOnly } from '@components/ViewOnly';
import { CustomerPickerItemFragment } from '@generated/fragments/customerPickerItem';
import { CustomerPickerItemV2Fragment } from '@generated/fragments/customerPickerItemV2';
import {
  AllCustomersForCustomerPickerV2Document,
  AllCustomersForCustomerPickerV2Query,
  AllCustomersForCustomerPickerV2QueryVariables,
} from '@generated/queries/allCustomersForCustomerPickerV2';
import { useLazyQueryWithDataPromise } from '@hooks/useLazyQueryWithDataPromise';
import { useTamedInputValue } from '@hooks/useTamedInputValue';
import { getNodesFromConnection } from '@utils/graphqlUtils';
import { pipeSeparator } from '@utils/htmlEntities';
import { pickBy } from 'lodash-es';
import { FC, ReactNode, useCallback, useEffect, useState } from 'react';

export type Node = CustomerPickerItemFragment | CustomerPickerItemV2Fragment;

export const CustomerPickerItemDisplay: FC<{
  customer: Maybe<Node>;
  compact?: boolean;
}> = ({ customer, compact }) => (
  <div>
    <strong>{customer?.name}</strong>
    {compact ? <br /> : ' | '}
    <span>
      {customer?.code}
      {pipeSeparator}
      <AddressDisplay value={customer?.mainAddress} city state />
      {pipeSeparator}
      {customer?.customerStatusType?.name}
    </span>
  </div>
);

interface Props extends Omit<AutoCompleteProps<Node>, 'items'> {
  disableSelectOnTab?: boolean;
  name?: string;
  value?: string;
  initialSelectedItem?: Shell<Node>;
  inputProps?: AutoCompleteProps<unknown>['inputProps'];
  /** Input id */
  id?: string;
  autoFocus?: boolean; // defaults to 'true'
  /** Initial customer id, if you want the component to do a lookup */
  initialId?: Maybe<string>;
  filterItems?: (items: Shell<Node>[]) => Shell<Node>[];
}

export const toShell = (value: Node): Shell<Node> => ({
  value,
  label: (): ReactNode => <CustomerPickerItemDisplay customer={value} />,
  id: value.id,
});

export const useCustomerSearch = (): ((
  text: string,
  kwargs?: AllCustomersForCustomerPickerV2QueryVariables
) => Promise<
  CustomerPickerItemFragment[] | CustomerPickerItemV2Fragment[]
>) => {
  const callQueryV2 = useLazyQueryWithDataPromise<
    AllCustomersForCustomerPickerV2Query,
    AllCustomersForCustomerPickerV2QueryVariables
  >(AllCustomersForCustomerPickerV2Document);

  return useCallback(
    async (
      text: string,
      kwargs?: AllCustomersForCustomerPickerV2QueryVariables
    ): Promise<CustomerPickerItemV2Fragment[]> => {
      const rawRes = await callQueryV2({
        fetchPolicy: 'network-only',
        variables: { first: 20, filter: pickBy({ text }), ...kwargs },
      });
      return getNodesFromConnection(rawRes.data.allCustomersV2);
    },
    [callQueryV2]
  );
};

export const CustomerPicker: FC<Props> = ({
  disableSelectOnTab,
  name,
  initialSelectedItem: initialSelectedItemProp,
  inputProps,
  id,
  filterItems,
  initialId,
  autoFocus = true,
  ...rest
}) => {
  const { isViewOnly } = useViewOnly();

  const [text, setText] = useTamedInputValue({
    initialValue: '',
    debounceTimeout: 400,
  });

  const [shouldUseInitialCustomer, setShouldUseInitialCustomer] = useState(
    Boolean(initialId)
  );

  const searchCustomers = useCustomerSearch();

  const [dropdownKey, setDropdownKey] = useState(Date.now());

  const [results, setResults] = useState<Node[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect((): void => {
    (async (): Promise<void> => {
      if (text.length > 2) {
        setLoading(true);
        const arr = await searchCustomers(text);
        setResults(arr);
        setLoading(false);
      } else if (initialId && shouldUseInitialCustomer) {
        setLoading(true);
        const arr = await searchCustomers(text, {
          filter: { id: initialId },
          first: 20,
        });
        setResults(arr);
        setLoading(false);
      } else if (text.length <= 2) {
        setLoading(false);
        setResults([]);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text, shouldUseInitialCustomer]);

  const items = results?.map(toShell) || [];

  let gotCustomerFromInitialId = false;

  let initialSelectedItem = initialSelectedItemProp;
  if (initialId && !initialSelectedItem) {
    initialSelectedItem = results
      .filter((obj) => obj.id === initialId)
      .map(toShell)[0];
    if (initialSelectedItem) {
      gotCustomerFromInitialId = true;
    }
  }

  useEffect(() => {
    if (shouldUseInitialCustomer) {
      setDropdownKey(Date.now());
    }
  }, [gotCustomerFromInitialId, shouldUseInitialCustomer]);

  return isViewOnly ? (
    <div>
      {rest.label && <Label css={{ marginBottom: '6px' }}>{rest.label}</Label>}
      <div data-testid={name || 'customer-search'}>
        {rest.selectedItem?.label}
      </div>
    </div>
  ) : (
    <AutoComplete<Node>
      key={dropdownKey}
      fsType="customer"
      fsName="customer"
      fsSearchElement="customer-search-bar"
      fsItemElement="customer-menu-item"
      fsParent="customer-picker"
      onInputValueChange={(e): void => {
        setShouldUseInitialCustomer(false);
        setText(e);
      }}
      loading={loading}
      items={filterItems ? filterItems(items) : items}
      data-testid="customer-search"
      inputProps={{
        placeholder: 'Search Customers',
        ['data-testid']: 'customer-search-input',
        name,
        id: id || name,
        autoFocus,
        ...inputProps,
      }}
      initialSelectedItem={initialSelectedItem}
      disableSelectOnTab={disableSelectOnTab}
      {...rest}
    />
  );
};
