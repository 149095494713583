import { TablePreferencesShape } from '@hooks/useTablePreference';
import { IDB_KEY, IndexedDBConfig, win } from '@utils/win';
import { useMemo } from 'react';
import { getActions, getConnection } from './db';
import { getActionsReturnType } from './types';

// ts-unused-exports:disable-next-line
export async function setupIndexedDB(config: IndexedDBConfig): Promise<void> {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise<void>(async (resolve, reject) => {
    try {
      await getConnection(config);
      win[IDB_KEY] = { init: 1, config };
      resolve();
    } catch (e) {
      reject(e);
    }
  });
}

interface StoreTypes {
  tablePref: TablePreferencesShape;
}

// ts-unused-exports:disable-next-line
export function useIndexedDBStore<K extends keyof StoreTypes>(
  storeName: K
): getActionsReturnType<StoreTypes[K]> {
  const _actions = useMemo(
    () => getActions<StoreTypes[K]>(storeName),
    [storeName]
  );
  return _actions;
}
